import React from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"


//assets
import link_expire from '../../static/images/link_expire.png'

const LinkExpired = () => (
    <Layout>
        <SEOComponent title="Amber by inFeedo | Expired Link" />
        <div class="content has-text-centered flex-wrap mar-top-160 mg-bottom-30 pd-top-30 pd-bottom-40 link-expired">
            <img width="300px" className="is-12" src={link_expire} alt="expired-link" />
            <p className="mg-top-20 is-12 is-size-4 has-text-weight-bold">
                You are trying to access an expired link
            </p>
        </div>
    </Layout>
)

export default LinkExpired

